import React from "react";
import { Table } from "react-bootstrap";

export const TableWithTheme = ({ children }) => (
  <Table
    borderless
    size="sm"
    css={(theme) => ({
      color: theme.colors.element1,
    })}
  >
    {children}
  </Table>
);

export default function Calendar() {
  return (
    <TableWithTheme>
      <thead>
        <tr>
          <th>
            <strong>AUGUST:</strong>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Wednesday</td>
          <td>26</td>
          <td>Board Meeting (6:45 Executive/7:30 Full Board)</td>
        </tr>

        <tr>
          <td>
            <strong>SEPTEMBER:</strong>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Tuesday</td>
          <td>8</td>
          <td>Virtual Orientation</td>
        </tr>

        <tr>
          <td>Mon/Tue</td>
          <td>14-15</td>
          <td>Teacher Visits at Watchung Coop Playground</td>
        </tr>

        <tr>
          <td>Wednesday</td>
          <td>16</td>
          <td>School Begins</td>
        </tr>

        <tr>
          <td>Wednesday</td>
          <td>23</td>
          <td>Board Meeting (6:45 Executive/7:30 Full Board)</td>
        </tr>

        <tr>
          <td>Monday</td>
          <td>28</td>
          <td>School Closed for Yom Kippur</td>
        </tr>

        <tr>
          <td>
            <strong>OCTOBER:</strong>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Monday</td>
          <td>12</td>
          <td>School Closed - Teacher In-Service</td>
        </tr>

        <tr>
          <td>Friday</td>
          <td>30</td>
          <td>Halloween Parade - 9 AM</td>
        </tr>

        <tr>
          <td>
            <strong>NOVEMBER:</strong>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Sunday</td>
          <td>1</td>
          <td>Second Tuition Payment Due</td>
        </tr>

        <tr>
          <td>Tuesday</td>
          <td>3</td>
          <td>No School- Election Day</td>
        </tr>

        <tr>
          <td>Thu/Fri</td>
          <td>5-6</td>
          <td>Closed for NJEA Convention</td>
        </tr>

        <tr>
          <td>Wednesday</td>
          <td>11</td>
          <td>Board Meeting (6:45 Executive/7:30 Full Board)</td>
        </tr>

        <tr>
          <td>Saturday</td>
          <td>14</td>
          <td>Clean Up Weekend</td>
        </tr>

        <tr>
          <td>Friday</td>
          <td>20</td>
          <td>Creative - Parent Teacher Conferences</td>
        </tr>

        <tr>
          <td>Wednesday</td>
          <td>25</td>
          <td>DPK Fall Fiesta/Creative Thanksgiving Feast</td>
        </tr>

        <tr>
          <td></td>
          <td></td>
          <td>Early Dismissal - 12 PM - No Lunch or PM Class</td>
        </tr>

        <tr>
          <td>Thu/Fri</td>
          <td>26-27</td>
          <td>School Closed for Thanksgiving</td>
        </tr>

        <tr>
          <td>
            <strong>DECEMBER:</strong>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Saturday</td>
          <td>5</td>
          <td>Open House for Prospective Families - 9-10:30 AM</td>
        </tr>

        <tr>
          <td>Wednesday</td>
          <td>9</td>
          <td>Board Meeting (6:45 Executive/7:30 Full Board)</td>
        </tr>

        <tr>
          <td>Monday</td>
          <td>21</td>
          <td>Winter Solstice - DPK</td>
        </tr>

        <tr>
          <td>Thu-Thu</td>
          <td>24-31</td>
          <td>School Closed for Winter Break</td>
        </tr>

        <tr>
          <td>
            <strong>JANUARY:</strong>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Fri-Sun</td>
          <td>1-3</td>
          <td>School Closed for Winter Break</td>
        </tr>

        <tr>
          <td>Monday</td>
          <td>4</td>
          <td>School Reopens</td>
        </tr>

        <tr>
          <td>Saturday</td>
          <td>9</td>
          <td>Open House for Prospective Families - 9-10:30 AM</td>
        </tr>

        <tr>
          <td>Mon-Wed</td>
          <td>11-13</td>
          <td>In-house Registration for 2021-22 School Year</td>
        </tr>

        <tr>
          <td>Monday</td>
          <td>18</td>
          <td>School Closed for Martin Luther King Jr. Day</td>
        </tr>

        <tr>
          <td>Wednesday</td>
          <td>20</td>
          <td>Board Meeting (6:45 Executive/7:30 Full Board)</td>
        </tr>

        <tr>
          <td>Saturday</td>
          <td>30</td>
          <td>Clean Up Weekend</td>
        </tr>

        <tr>
          <td>
            <strong>FEBRUARY:</strong>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Monday</td>
          <td>1</td>
          <td>Third Tuition Payment Due</td>
        </tr>

        <tr>
          <td>Wed/Thu</td>
          <td>3-4</td>
          <td>DPK Parent/Teacher Conferences</td>
        </tr>

        <tr>
          <td>Thursday</td>
          <td>6</td>
          <td>Early Dismissal - 12 PM - No Lunch or PM Class</td>
        </tr>

        <tr>
          <td>Friday</td>
          <td>12</td>
          <td>Family Day 9 AM</td>
        </tr>

        <tr>
          <td>Monday</td>
          <td>15</td>
          <td>School Closed for Presidents&rsquo; Day</td>
        </tr>

        <tr>
          <td>
            <strong>MARCH:</strong>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Tuesday</td>
          <td>2</td>
          <td>Green Eggs &amp; Ham Celebration - All Classes 9 AM</td>
        </tr>

        <tr>
          <td>Friday</td>
          <td>5</td>
          <td>
            Spring Social &amp; Silent Auction - 7 PM Location TBD or Virtual
          </td>
        </tr>

        <tr>
          <td>Wednesday</td>
          <td>10</td>
          <td>Board Meeting (6:45 Executive/7:30 Full Board)</td>
        </tr>

        <tr>
          <td>Saturday</td>
          <td>27</td>
          <td>Clean Up Weekend</td>
        </tr>

        <tr>
          <td>
            <strong>APRIL:</strong>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Thursday</td>
          <td>1</td>
          <td>Fourth Quarter Tuition Payment Due</td>
        </tr>

        <tr>
          <td>Friday</td>
          <td>2</td>
          <td>School Closed for Good Friday</td>
        </tr>

        <tr>
          <td>Mon-Fri</td>
          <td>5-9</td>
          <td>School Closed for Spring Break</td>
        </tr>

        <tr>
          <td>
            <strong>MAY:</strong>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Saturday</td>
          <td>1</td>
          <td>Remainder of 1st Tuition Payment Due for 2021-22 School Year</td>
        </tr>

        <tr>
          <td>Mon/Fri</td>
          <td>3-7</td>
          <td>Literacy Week</td>
        </tr>

        <tr>
          <td>Wednesday</td>
          <td>12</td>
          <td>Board Meeting (6:45 Executive/7:30 Full Board)</td>
        </tr>

        <tr>
          <td>Friday</td>
          <td>14</td>
          <td>Creative Parent/Teacher Conferences</td>
        </tr>

        <tr>
          <td>Monday</td>
          <td>17</td>
          <td>Creative Parent/Teacher Conferences</td>
        </tr>

        <tr>
          <td>Fri/Mon</td>
          <td>28-31</td>
          <td>School Closed for Memorial Day</td>
        </tr>

        <tr>
          <td>
            <strong>JUNE:</strong>
          </td>
          <td></td>
          <td></td>
        </tr>

        <tr>
          <td>Monday</td>
          <td>7</td>
          <td>Summer Birthday Celebrations</td>
        </tr>

        <tr>
          <td>Thursday</td>
          <td>10</td>
          <td>DPK Celebration 9 AM</td>
        </tr>

        <tr>
          <td></td>
          <td></td>
          <td>Last Day of School</td>
        </tr>

        <tr>
          <td>Friday</td>
          <td>11</td>
          <td>School-wide Picnic - 10 AM Location TBD</td>
        </tr>

        <tr>
          <td>Sat/Mon/Tue</td>
          <td>12/14/15</td>
          <td>End-of-year Clean-up</td>
        </tr>

        <tr>
          <td>Wednesday</td>
          <td>16</td>
          <td>Board Potluck Dinner - 7 PM Location TB</td>
        </tr>

        <tr>
          <td>Monday</td>
          <td>21</td>
          <td>Summer Camp Start Date</td>
        </tr>
      </tbody>
    </TableWithTheme>
  );
}
