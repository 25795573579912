import React from "react";

export default function Caption({ children }) {
  return (
    <caption 
      css={ theme => ({ 
        fontFamily: "cooper hewitt,helvetica neue,helvetica,arial,sans-serif",
        fontKerning: "auto",
        fontSize: "1.125rem",
        fontWeight: 800,
        color: theme.colors.pager, 
        textAlign: 'center', 
        display: 'block' 
      })}
    >
      {children}
    </caption>
  )
} 