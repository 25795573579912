// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apply-mdx": () => import("./../../../src/pages/apply.mdx" /* webpackChunkName: "component---src-pages-apply-mdx" */),
  "component---src-pages-calendar-mdx": () => import("./../../../src/pages/calendar.mdx" /* webpackChunkName: "component---src-pages-calendar-mdx" */),
  "component---src-pages-classes-mdx": () => import("./../../../src/pages/classes.mdx" /* webpackChunkName: "component---src-pages-classes-mdx" */),
  "component---src-pages-covid-19-mdx": () => import("./../../../src/pages/covid19.mdx" /* webpackChunkName: "component---src-pages-covid-19-mdx" */),
  "component---src-pages-dpk-curriculum-mdx": () => import("./../../../src/pages/dpk-curriculum.mdx" /* webpackChunkName: "component---src-pages-dpk-curriculum-mdx" */),
  "component---src-pages-enrichment-mdx": () => import("./../../../src/pages/enrichment.mdx" /* webpackChunkName: "component---src-pages-enrichment-mdx" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-musictogether-mdx": () => import("./../../../src/pages/musictogether.mdx" /* webpackChunkName: "component---src-pages-musictogether-mdx" */),
  "component---src-pages-objectives-mdx": () => import("./../../../src/pages/objectives.mdx" /* webpackChunkName: "component---src-pages-objectives-mdx" */),
  "component---src-pages-parents-mdx": () => import("./../../../src/pages/parents.mdx" /* webpackChunkName: "component---src-pages-parents-mdx" */),
  "component---src-pages-staff-mdx": () => import("./../../../src/pages/staff.mdx" /* webpackChunkName: "component---src-pages-staff-mdx" */),
  "component---src-pages-support-mdx": () => import("./../../../src/pages/support.mdx" /* webpackChunkName: "component---src-pages-support-mdx" */),
  "component---src-pages-tuition-mdx": () => import("./../../../src/pages/tuition.mdx" /* webpackChunkName: "component---src-pages-tuition-mdx" */),
  "component---src-pages-visit-mdx": () => import("./../../../src/pages/visit.mdx" /* webpackChunkName: "component---src-pages-visit-mdx" */),
  "component---src-pages-welcome-mdx": () => import("./../../../src/pages/welcome.mdx" /* webpackChunkName: "component---src-pages-welcome-mdx" */)
}

