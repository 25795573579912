import React from "react";

export default function Video({ videoSrcURL, videoTitle }) {
  return (
    <div
      className="video"
      css={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "56.25%" /* 16:9 Aspect Ratio */,
      }}
    >
      <iframe
        css={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
        }}
        src={videoSrcURL}
        title={videoTitle}
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  );
}
